<template>
    <Component :is="is.tag" v-bind="is.attrs" :class="themeClasses.default"
        ><slot></slot
    ></Component>
</template>
<script setup lang="ts">
import type { NuxtLinkProps } from 'nuxt/app'

const props = defineProps({
    theme: {
        type: String as PropType<'primary' | 'secondary' | 'green'>,
        default: 'primary',
    },
    to: {
        type: [String, Object] as PropType<NuxtLinkProps['to']>,
        default: undefined,
    },
})

const themeClasses = computed(() => {
    return {
        primary: {
            default:
                'text-blue hover:underline underline-offset-4 duration-300 decoration-transparent hover:decoration-blue/50 transition-all',
            disabled: '',
        },
        secondary: {
            default:
                'text-grey hover:underline underline-offset-4 duration-300 decoration-transparent hover:decoration-grey/50 transition-all',
            disabled: '',
        },
        green: {
            default:
                'text-green hover:underline underline-offset-4 duration-300 decoration-transparent hover:decoration-green/50 transition-all',
            disabled: '',
        },
    }[props.theme]
})

const $attrs = useAttrs()

const is = computed(() => {
    if (props.to) {
        return {
            tag: defineNuxtLink({}),
            attrs: {
                to: props.to,
            },
        }
    }
    return {
        tag: 'a',
        attrs: $attrs,
    }
})
</script>
